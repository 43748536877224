import * as _ from 'lodash';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { ApiService } from './api.service';

export enum FileQueueStatus {
	Pending,
	Success,
	Error,
	Progress
}

export class FileQueueObject {
	public file: any;
	public status: FileQueueStatus = FileQueueStatus.Pending;
	public progress: number = 0;
	public request!: Subscription;
	public response!: HttpResponse<any> | HttpErrorResponse;
	public uploaded_file_name!:string;
	public uploaded_file_ext!:string;
	public uploaded_orig_name!:string;
	public temp_file_name!:string;
	public temp_file_ext!:string;
	public temp_orig_name!:string;
	public s3_url!:string;
	
	constructor(fileData: any) {
		this.file = fileData.file;
		this.temp_file_name = fileData.temp_file_name;
		this.temp_file_ext = fileData.temp_file_ext;
		this.temp_orig_name = fileData.temp_orig_name;
		this.s3_url = fileData.s3_url;
	}
	
	/* actions */
	public upload = () => { /* set in service */ };
	public cancel = () => { /* set in service */ };
	public remove = () => { /* set in service */ };
	
	/* statuses */
	public isPending = () => this.status === FileQueueStatus.Pending;
	public isSuccess = () => this.status === FileQueueStatus.Success;
	public isError = () => this.status === FileQueueStatus.Error;
	public inProgress = () => this.status === FileQueueStatus.Progress;
	public isUploadable = () => this.status === FileQueueStatus.Pending || this.status === FileQueueStatus.Error;
}

@Injectable({
	providedIn: 'root'
})
export class FileuploadService {
	private _queue: BehaviorSubject<FileQueueObject[]>;
	private _files: FileQueueObject[] = [];
	
	constructor(private http: HttpClient,private apiService: ApiService) {
		this._queue = <BehaviorSubject<FileQueueObject[]>>new BehaviorSubject(this._files);
	}
	
	/* the queue */
	public get queue() {
		return this._queue.asObservable();
	}
	
	/* public events */
	public onCompleteItem(queueObj: FileQueueObject, response: any): any {
		return { queueObj, response };
	}
	
	/* public functions */
	public addToQueue(data: any) {
		/* add file to the queue */
		_.each(data, (fileData: any) => this._addToQueue(fileData));
	}
	
	public clearQueue() {
		/* clear the queue */
		this._files = [];
		this._queue.next(this._files);
	}
	
	public uploadAll() {
		/* upload all except already successfull or in progress */
		_.each(this._files, (queueObj: FileQueueObject) => {
			if (queueObj.isUploadable()) {
				this._upload(queueObj);
			}
		});
	}
	
	/* private functions */
	private _addToQueue(fileData: any) {
		const queueObj = new FileQueueObject(fileData);
		
		/* set the individual object events */
		queueObj.upload = () => this._upload(queueObj);
		queueObj.remove = () => this._removeFromQueue(queueObj);
		queueObj.cancel = () => this._cancel(queueObj);
		
		/* push to the queue */
		this._files.push(queueObj);
		this._queue.next(this._files);
	}
	
	private _removeFromQueue(queueObj: FileQueueObject) {
		_.remove(this._files, queueObj);
	}
	
	private _upload(queueObj: FileQueueObject) {
		/* create form data for file */
		const form = new FormData();
		form.append('file', queueObj.file, queueObj.file.name);
		/* form.append('filename ', queueObj.file.name); */
		form.append('destination', "attachments/tele-medicine");
		
		
		/*_headers.set("x-auth-token",localStorage.getItem('tokenKey'));
		_headers.set("x-auth-token",localStorage.getItem('tokenKey'));*/
		
		/* upload file and report progress */
		const req = new HttpRequest('PUT', queueObj.s3_url, queueObj.file, {
			reportProgress: true
		});
		
		/* upload file and report progress */
		queueObj.request = this.http.request(req).subscribe((event: any) => {
			if (event.type === HttpEventType.UploadProgress) {
				this._uploadProgress(queueObj, event);
			} else if (event instanceof HttpResponse) {
				this._uploadComplete(queueObj, event);
			}
		},(err: HttpErrorResponse) => {
			console.log(err)
			if (err.error instanceof Error) {
				/* A client-side or network error occurred. Handle it accordingly. */
				this._uploadFailed(queueObj, err);
			} else {
				/* The backend returned an unsuccessful response code. */
				this._uploadFailed(queueObj, err);
			}
		});
		return queueObj;
	}
	
	private _cancel(queueObj: FileQueueObject) {
		/* update the FileQueueObject as cancelled */
		queueObj.request.unsubscribe();
		queueObj.progress = 0;
		queueObj.status = FileQueueStatus.Pending;
		this._queue.next(this._files);
	}
	
	private _uploadProgress(queueObj: FileQueueObject, event: any) {
		/* update the FileQueueObject with the current progress */
		const progress = Math.round(100 * event.loaded / event.total);
		queueObj.progress = progress;
		queueObj.status = FileQueueStatus.Progress;
		this._queue.next(this._files);
	}
	
	private _uploadComplete(queueObj: FileQueueObject, response: HttpResponse<any>) {
		/* update the FileQueueObject as completed */
		queueObj.progress = 100;
		queueObj.status = FileQueueStatus.Success;
		queueObj.response = response;
		this._queue.next(this._files);
		this.onCompleteItem(queueObj, response.body);
	}
	
	private _uploadFailed(queueObj: FileQueueObject, response: HttpErrorResponse) {
		/* update the FileQueueObject as errored */
		queueObj.progress = 0;
		queueObj.status = FileQueueStatus.Error;
		queueObj.response = response;
		this._queue.next(this._files);
	}
	
}